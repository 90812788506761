import React from "react";
import './Footer.css';
import { linkedIn, twitter, message, instagram } from "../../../img/logo/index.js";



function Footer(){
	return (
		<React.Fragment>
			<footer className="footer">
				<p className="footer">PLASTIC ANALYTICS 2020 &copy;</p>&nbsp;<p className="footer">|</p>&nbsp;
				<a className="footer" href="https://twitter.com/plasticanalytic/"><img src={twitter} className="footerIcon"></img></a>&nbsp;
				<a className="footer" href="https://www.linkedin.com/company/plasticanalytics/"><img src={linkedIn} className="footerIcon"></img></a>&nbsp;
				<a className="footer" href="https://www.instagram.com/plasticanalytics/"><img src={instagram} className="footerIcon"></img></a> &nbsp;
				<p className="footer">|</p>&nbsp;
				<a className="footer" href="./Comingsoon"><p className="footer">Privacy</p></a>&nbsp;
				<a className="footer" href="./Comingsoon"><p className="footer">Terms</p></a>&nbsp;
				<a className="footer" href="./Comingsoon"><p className="footer">Settings</p></a>&nbsp;
				<a className="footer" href="./Comingsoon"><p className="footer">Contact Us</p></a>&nbsp;
			</footer>
			<h4 class="page_end">Powered by &nbsp; 
                <a class="nav-email-2" href="https://www.levermoreandcode.co.uk/">
                     Levermore and Code.
                </a>
            </h4>
		</React.Fragment>
	)
};

export default Footer;

