import React from 'react';

import { airborneDust } from "../../../img/environmentalImages/index.js";
import './Contactus.css';
// Meta data 
import MetaTags from '../metaTags/MetaTags.js';
import { pageMetaTags } from '../metaTags/MetaData.js';
function ContactUs(){
    const meta = pageMetaTags.contactUs;
	return(
		<React.Fragment>
        <MetaTags
				title={meta.title}
				description={meta.description}
				url={meta.url}
				image={meta.image}
				siteName={meta.siteName}
				twitterCard={meta.twitterCard}
				twitterSite={meta.twitterSite}
				fbAppId={meta.fbAppId}
			/>
        <h2 className = "contact">Contact us!</h2>
				<p className = "contactDesc">
                    Please see below contact information for the PlasticAnalytics Team!
                </p>
            <div className="row-info">
                <div className="columnContactus">
                <div class="vertical-center">
                    
					<p className = "contact">
                        <b>Lead Web Developer:</b> Dr. Joseph Levermore <br/>
						<b>Email:</b> <a className = "contactUsemail" href="mailto:j.xxlexxverxxmore@imxxpexxrixxal.axxc.uxxkx" onmouseover="this.href=this.href.replace(/x/g,'');">j.levermore@imperial.ac.uk</a>
                    </p>
                    <p className = "contact">
						<b>Microplastic Team Lead, ICL:</b> Dr. Stephanie Wright <br/>
						<b>Email:</b> <a className = "contactUsemail" href="mailto:s.wrxxightxx19@imxxperxxial.axxcxx.uxxk" onmouseover="this.href=this.href.replace(/x/g,'');">s.wright19@imperial.ac.uk</a>
                    </p>
					<p className = "contact">
						<b>Deputy Director of MRC Centre, ICL:</b> Professor Frank Kelly <br/>
						<b>Email:</b> <a className = "contactUsemail" href="mailto:xfrxxxank.kxxelxxly@imperial.ac.xxuk" onmouseover="this.href=this.href.replace(/x/g,'');">frank.kelly@imperial.ac.uk</a>
                    </p>
                </div>

                </div>
                <div className="columnContactus">
                <div class="vertical-center">
                    <div className = "imageContainerContact">
                            <img
                                src={airborneDust} 
								width="70%"
                            />  
                        </div>
                    </div>
                </div>
            </div>
		</React.Fragment>
	);
}

export default ContactUs;

