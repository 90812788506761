import React, { useState } from 'react';
import './MetaData.css';

function MetaData({ onChange, onInputChange }) {
  const [selectedOption, setSelectedOption] = useState('');
  const [inputValues, setInputValues] = useState({
    inputOneValue: '',
    inputTwoValue: '',
    inputThreeValue: '',
    inputFourValue: '',
  });

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    onChange(selectedValue);
  };

  const handleInputChange = (fieldName, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
    onInputChange({ ...inputValues, [fieldName]: value }); // Send updated input values to the parent
  }; 

  return (
    <div>
      <label 
      htmlFor="dropdown" 
      style={{ color: "#fff" }}
      className='MetaDataInput'
      >Select an option:</label>
      <select id="dropdown" value={selectedOption}
      className='MetaDataInput'
      onChange={handleSelectChange}>
        <option value="">Select</option>
        <option value="Virgin">Virgin</option>
        <option value="Consumer">Consumer</option>
        <option value="Environmental">Environmental</option>
      </select>

      {selectedOption === 'Virgin' && (
        <>
        <br />
          <label htmlFor="input1" 
          className='MetaDataInput'
          style={{ color: "#fff" }}>
            Source:
          </label>
          <input
              type="text"
              className='MetaDataInput'
              id="input1"
              value={inputValues.inputOneValue}
              onChange={(e) => handleInputChange('inputOneValue', e.target.value)}
              placeholder="Enter Source"
            />
            <br />
            <label 
            htmlFor="input2" 
            className='MetaDataInput'
            style={{ color: "#fff" }}>
            Colour:
            </label>
            <input
                type="text"
                id="input2"
                className='MetaDataInput'
                value={inputValues.inputTwoValue}
                onChange={(e) => handleInputChange('inputTwoValue', e.target.value)}
                placeholder="Enter colour"
              />
              <br />
            <label 
            htmlFor="input3"
            className='MetaDataInput'
            style={{ color: "#fff" }}>
            Associated publication:
            </label>
            <input
                type="text"
                id="input3"
                className='MetaDataInput'
                value={inputValues.inputThreeValue}
                onChange={(e) => handleInputChange('inputThreeValue', e.target.value)}
                placeholder="Enter Source"
              />
              <br />
              <label 
              htmlFor="input4" 
              className='MetaDataInput'
              style={{ color: "#fff" }}>
              Description:
              </label>
              <input
                  type="text"
                  id="input4"
                  value={inputValues.inputFourValue}
                  className='MetaDataInput'
                  onChange={(e) => handleInputChange('inputFourValue', e.target.value)}
                  placeholder="Enter Description"
                />
        </>
      )}

      {selectedOption === 'Consumer' && (
        <>
        <br />
        <label 
        htmlFor="input1" 
        className='MetaDataInput'
        style={{ color: "#fff" }}>
          Source:
        </label>
        <input
            type="text"
            id="input1"
            className='MetaDataInput'
            value={inputValues.inputOneValue}
            onChange={(e) => handleInputChange('inputOneValue', e.target.value)}
            placeholder="Enter Source"
          />
          <br />
          <label 
          htmlFor="input2" 
          className='MetaDataInput'
          style={{ color: "#fff" }}>
          Colour:
          </label>
          <input
              type="text"
              id="input2"
              className='MetaDataInput'
              value={inputValues.inputTwoValue}
              onChange={(e) => handleInputChange('inputTwoValue', e.target.value)}
              placeholder="Enter colour"
            />
          <br />
          <label 
            htmlFor="input3" 
            className='MetaDataInput'
            style={{ color: "#fff" }}>
          Associated publication:
          </label>
          <input
              type="text"
              id="input3"
              className='MetaDataInput'
              value={inputValues.inputThreeValue}
              onChange={(e) => handleInputChange('inputThreeValue', e.target.value)}
              placeholder="Enter Source"
            />
            <br />
            <label
             htmlFor="input4" 
             className='MetaDataInput'
             style={{ color: "#fff" }}>
            Description:
            </label>
            <input
                type="text"
                id="input4"
                className='MetaDataInput'
                value={inputValues.inputFourValue}
                onChange={(e) => handleInputChange('inputFourValue', e.target.value)}
                placeholder="Enter Description"
              />
      </>
      )}

      {selectedOption === 'Environmental' && (
          <>
          <br />
          <label
            htmlFor="input1" 
            className='MetaDataInput'
            style={{ color: "#fff" }}>
            Source:
          </label>
          <input
              type="text"
              id="input1"
              className='MetaDataInput'
              value={inputValues.inputOneValue}
              onChange={(e) => handleInputChange('inputOneValue', e.target.value)}
              placeholder="Enter Source"
            />
            <br />
            <label 
              htmlFor="input2" 
              style={{ color: "#fff" }}
              className='MetaDataInput'>
            Colour:
            </label>
            <input
                type="text"
                id="input2"
                className='MetaDataInput'
                value={inputValues.inputTwoValue}
                onChange={(e) => handleInputChange('inputTwoValue', e.target.value)}
                placeholder="Enter colour"
              />
              <br />
            <label 
              htmlFor="input3" 
              style={{ color: "#fff" }}
              className='MetaDataInput'>
            Associated publication:
            </label>
            <input
                type="text"
                id="input3"
                className='MetaDataInput'
                value={inputValues.inputThreeValue}
                onChange={(e) => handleInputChange('inputThreeValue', e.target.value)}
                placeholder="Enter Source"
              />
              <br />
              <label 
              htmlFor="input4" 
              style={{ color: "#fff" }}
              className='MetaDataInput'
              >
              Description:
              </label>
              <input
                  type="text"
                  id="input4"
                  className='MetaDataInput'
                  value={inputValues.inputFourValue}
                  onChange={(e) => handleInputChange('inputFourValue', e.target.value)}
                  placeholder="Enter Description"
                />
        </>
      )}
    </div>
  );
}

export default MetaData;
