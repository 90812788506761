import React, { useState } from 'react';

function InstrumentVariedInput({ instrumentType, onChange }) {
  const [inputTwoValue, setInputTwoValue] = useState('');

  const handleInputTwoChange = (e) => {
    const inputValue = e.target.value;
    setInputTwoValue(inputValue);
    onChange(inputValue);
  };
//console.log('Input varied', instrumentType)
  return (
    <div>
      {instrumentType === 'Fourier Transform Infrared Spectroscopy' && (
        <>
        <label className='inputSpectralLibrary' htmlFor="input2" style={{  color: "#fff" }}>Mode:
          <select value={inputTwoValue} onChange={handleInputTwoChange}>
            <option value="">Select</option>
            <option value="Transmittance">Transmittance</option>
            <option value="Reflectance">Reflectance</option>
          </select>
        </label>
        <p className='spectralOutputs'>
            Mode: {inputTwoValue}
        </p>
        </>
      )}

      {instrumentType === 'Raman Microscopy' && (
        <>
      <label className='inputSpectralLibrary' htmlFor="input2" style={{  color: "#fff" }}>
          Wavelength:
          <select value={inputTwoValue} onChange={handleInputTwoChange}>
            <option value="">Select</option>
            <option value="532">532</option>
            <option value="633">633</option>
            <option value="785">785</option>
            <option value="1062">1062</option>
          </select>
        </label>
        <br />
        <p className='spectralOutputs'>
            Wavelength: {inputTwoValue}
        </p>
        </>
      )}
    </div>
  );
}

export default InstrumentVariedInput;
