import React, { useState, useEffect } from 'react'; 
import { getFunctions, httpsCallable } from "firebase/functions";
import '../SpectralLibrary.css'
import { app, fbStorage } from '../../firebase/firebase';
import { uploadBytes, ref, deleteObject, getMetadata, updateMetadata } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PolymerListInput from '../spectralLibraryHomePage/components/PolymerListInput/PolymerListInput';
import InstrumentListInput from '../spectralLibraryHomePage/components/InstrumentListInput/InstrumentListInput';
import domtoimage from 'dom-to-image';
import InstrumentVariedInput from '../spectralLibraryHomePage/components/InstrumentVariedInput/InstrumentVariedInput';
import './Chemometrics.css'
import Navigation from '../../Components/Functionality/navigation/Navigation';
import PlotData from '../spectralLibraryHomePage/components/SpectralPlotter/PlotData';
import { userAuth } from '../../firebase/firebase';
import MetaData from '../spectralLibraryHomePage/components/MetaData/MetaData';
import '../../App.css'
export const Chemometrics = () => {
    const [inputs, setInputs] = useState({ input1: "", input2: "", input3: "" });
    const [showRightColumn, setShowRightColumn] = useState(false);
    const [selectedInstrument, setSelectedInstrument] = useState('');
    const [selectedMetaDropName, setSelectedMetaDropName] = useState('');
    const [metadataFields, setMetadataFields] = useState({
      inputOneValue: '',
      inputTwoValue: '',
      inputThreeValue: '',
      inputFourValue: '',
    });

    const [fileCode, setFileCode] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [variableInputValue, setVariableInputValue] = useState('');
    const [variableTwoInputValue, setVariableTwoInputValue] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [predictionAcc, setPredictionAcc] = useState('');
    const [predictedPolymer, setPredictedPolymer] = useState('');
    const [chartImage, setChartImage] = useState(null);
    const functions = getFunctions(app);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((prevState) => ({ ...prevState, [name]: value }));
    };

  const handleInstrumentNameChange = (value) => {
    setSelectedInstrument(value);
  };
  const handleMetaDropNameChange = (value) => {
    setSelectedMetaDropName(value);
    //console.log('meta data,', value);
  };

  function generateRandomFileCode(length) {
    let charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';
  
    for (let i = 0; i < length; i++) {
      let randomIndex = Math.floor(Math.random() * charset.length);
      code += charset.charAt(randomIndex);
    }
    return code;
  }

  const handleInputTwoChange = (value) => {
    setVariableInputValue(value);
  };
  
    const handleFile = (e) => {
      if (e.target.files[0]) {
        setSelectedFile(e.target.files[0]);
      }
    };
    const handleMetadataInputChange = (fieldName, value) => {
      setMetadataFields({
        inputOneValue: fieldName.inputOneValue,
        inputTwoValue: fieldName.inputTwoValue,
        inputThreeValue: fieldName.inputThreeValue,
        inputFourValue: fieldName.inputFourValue,
      });
    };
    
    const handleClick = async () => {
      if (
        selectedFile && 
        selectedInstrument &&
        variableInputValue &&
        selectedMetaDropName          
        ) {
      // remain File
      let fileCode = generateRandomFileCode(8);
      setFileCode(fileCode); 
      // send file to temp folder 
      const chemoRef = ref(fbStorage, `tempFiles/chemometrics/${fileCode}`);
      await uploadBytes(chemoRef, selectedFile);
      setShowRightColumn(true)
      // trigger the httpsCallable chemometricAnalysis function
      toast.success('uploaded successfully!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 150, // milliseconds
        style: {
          color: '#282c34', // Blue text color
        },
        progressStyle: {
          background: '#282c34', // Orange progress bar color
        },
      });
      const instance = httpsCallable(functions, 'plastic_categorisation');
      try {
          const response = await instance({
          fileLocation: `tempFiles/chemometrics/${fileCode}`,
          instrument: `${selectedInstrument}`,
          variable: `${variableInputValue}`
        });
        //return data for chart
        //console.log('Response:', response);
        setFileUrl(response.data.fileLocation)
        const predictionCert = response.data.predictionCert
        const prediction = predictionCert * 100
        const formattedResult = prediction.toFixed(2) + '%';
        setPredictionAcc(formattedResult)
        setPredictedPolymer(response.data.best_match)
      } catch (error) {
        console.error('Error calling chemometricAnalysis function:', error.message);
      }
      deleteObject(chemoRef).then(() => {
        // File deleted successfully
      }).catch((error) => {
        // error occurred!
      });
      const fileRef = ref(fbStorage, fileUrl);
      const newMetadata = {
        customMetadata: {
          'type':`${selectedMetaDropName}`,
          'source': `${metadataFields.inputOneValue}`, 
          'colour': `${metadataFields.inputTwoValue}`, 
          'associatedPublication': `${metadataFields.inputThreeValue}`, 
          'description': `${metadataFields.inputFourValue}`, 
          'uploadedBy': `${userAuth.currentUser.email}`
        }
      };
      updateMetadata(fileRef, newMetadata)
      .then(() => {
        //console.log('Metadata updated successfully'); 
        return getMetadata(fileRef);
      })
      .then((fetchedMetadata) => {
        //console.log(fetchedMetadata); 
      })
      .catch((error) => {
          //console.log(error);
      });
    }
  }
  const handleDownload = () => {
    // Use dom-to-image library to convert chart to base64 image
    domtoimage.toPng(document.getElementById('chart-container'))
      .then((dataUrl) => {
        setChartImage(dataUrl);
        // Trigger download
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'chart.png';
        link.click();
      })
      .catch((error) => {
        console.error('Error generating chart image:', error);
      });
  };

    return (
      <>
      <Navigation/>
        <div className='entireView'>
        <div className='columnLeft'>
         <h2 style={{  color: "#fff", marginLeft: '5%'}}>
            Categorise your plastic spectra
          </h2>
          <p style={{  color: "#fff", marginLeft: '5%'}}>
            Before doing so, please check that your spectrum is in a tab-delimited text file. It should have two columns (column 1: Wavelength; column 2: Intensity values). Once categorized using our library, your spectrum will be uploaded to our database. To ensure we produce a useful resource for all, please ensure the included metadata is accurate.
          </p>
          <br />
          <div>
            <InstrumentListInput onChange={handleInstrumentNameChange} />
            <p className='spectralOutputs'>Instrument selected: {selectedInstrument}</p>
            <InstrumentVariedInput instrumentType={selectedInstrument} onChange={handleInputTwoChange} />
         </div>
          <div>
            <h3 className='spectralPlotter'>
              Sample metadata:
            </h3>
            <MetaData onChange={handleMetaDropNameChange} onInputChange={handleMetadataInputChange} />
         </div>
          <br />
          <input className='uploadInput' type="file" onChange={handleFile}/>
          <button
          className='spectralButton'
          onClick={handleClick}>
            Analyse
            </button>
        </div>
        {showRightColumn && (
          <div className='columnRight'
          >
            <div id="chart-container"
            style={{backgroundColor:'#fff'}}
            >
            <PlotData  
            url={fileUrl}
            pageName={'Chemometrics'}
            match={predictedPolymer}
            instrument={selectedInstrument}
            variable={variableInputValue}
            />
            <div>
            {predictionAcc && (
              <div>
                <center>
              <p className='prediction'>
                 Hit Quality Index Score: {predictionAcc}
              </p>
              <p className='prediction'>
                 Predicted plastic type: {predictedPolymer}
              </p>
              </center>
              </div>
            )}
            </div>
            <br/>
            </div>
            <button 
            onClick={handleDownload}
            className='downloadButton'
            >Download Chart</button>
          </div>
        )}
          <ToastContainer />
      </div>
      </>
  )
}
