import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification
} from "firebase/auth";
import { userAuth } from "../../../firebase/firebase";
import { pageMetaTags } from "../metaTags/MetaData";
import MetaTags from "../metaTags/MetaTags";
const Register = () => {
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const register = async (event) => {
    event.preventDefault();
    if (registerPassword !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    if (registerEmail === "" || registerPassword === "") {
      alert("Email and password fields cannot be empty");
      return;
    }
    try {
      const { user } = await createUserWithEmailAndPassword(
        userAuth,
        registerEmail,
        registerPassword
      );
      await sendEmailVerification(user);
      alert("Verification email sent! Please check your inbox.");
      window.location.href = "/SpectralLibrary";
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const meta = pageMetaTags.registerToday;

  return (
    <>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div className="outer">
        <div className="login-container" id="login-container">
          <div className="form-container log-in-container">
            <form onSubmit={register} className="login">
              <h1>Register Today</h1>
              <div className="social-container"></div>
              <span>Email:</span>
              <input
                type="email"
                placeholder="Email..."
                onChange={(event) => setRegisterEmail(event.target.value)}
                required
              />
              <span>Password:</span>
              <input
                type="password"
                placeholder="Password..."
                onChange={(event) => setRegisterPassword(event.target.value)}
                required
              />
              <span>Confirm Password:</span>
              <input
                type="password"
                placeholder="Confirm Password..."
                onChange={(event) => setConfirmPassword(event.target.value)}
                required
              />
              <button type="submit" className="login-button">
                Register here
              </button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-right">
                <h1 className="login">Welcome to Plastic Analytics Spectral</h1>
                <p className="login">
                  To access our free, open-source spectral library, please register today! Remember to ensure your password contains at least 8 characters
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
