import './PolymerListInput.css'; 
import React, { useState, useEffect, useRef} from 'react';
import {getDatabase, query, ref, onValue} from "firebase/database";
import { db } from '../../../../firebase/firebase';


function PolymerListInput( {onChange} ) {
  const [databaseValues, setDatabaseValues] = useState([]);
  const [valueSelected, setValueSelected] = useState(false);
  const [userInput, setUserInput] = useState('');
  const inputRef = useRef(null);
  
  // Fetch values from Firebase and update the state
  useEffect(() => {
    const fetchData = async () => {
        const polymerList = query(ref(db, 'ListOfPolymers/'))
        onValue(polymerList, (snapshot) => {
          const data = snapshot.val();
          const valuesArray = Object.values(data)
          //console.log('data', valuesArray)
          setDatabaseValues(valuesArray);
        });
    };
    fetchData();
  }, []);
  
  // Handle user input change
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
    setValueSelected(false);
  };
  
  const handleItemClick = (value) => {
    setUserInput(value);
    setValueSelected(true);
    inputRef.current.focus();
    onChange(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      const matchingValue = databaseValues.find(
        (value) => value.toLowerCase() === userInput.toLowerCase()
      );
      if (matchingValue) {
        setUserInput(matchingValue);
        setValueSelected(true);
        inputRef.current.focus();
        e.preventDefault();
      }
    }
  };


  return (
    <div>
      {/* Render the autocomplete dropdown */}
      <label className='inputSpectralLibrary' htmlFor="input1" style={{  color: "#fff" }}>Polymer type:</label>
      <input
        type="text"
        className='inputSpectralLibrary'
        value={userInput}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        ref={inputRef}
      />
      {!valueSelected && userInput && (
        <div className="dropdown-container">
          <ul className="dropdown-list">
            {databaseValues
              .filter((value) => value.toLowerCase().includes(userInput.toLowerCase()))
              .map((value, index) => (
                <li
                  key={index}
                  onClick={() => handleItemClick(value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleItemClick(value);
                    }
                  }}
                  tabIndex={0}
                >
                  {value}
                </li>
              ))}
            {databaseValues.filter(
              (value) =>
                !value.toLowerCase().includes(userInput.toLowerCase()) &&
                valueSelected === false
            ).length === databaseValues.length && (
              <li className="not-found">Not found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default PolymerListInput;
