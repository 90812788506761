import { useState, createContext, useEffect, useLayoutEffect} from 'react';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import { useLocation, Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { Header, Footer, Homepage, Comingsoon, Contactus } from './Components/Functionality/'
import { SpectralLibraryHomePage } from './spectralLibrary/spectralLibraryHomePage/spectralLibraryHomePage';
import { UploaderHomePage } from './spectralLibrary/Uploader/uploaderHomePage';
import { Chemometrics } from './spectralLibrary/Chemometrics/Chemometrics';
import { userAuth } from './firebase/firebase';
import { onAuthStateChanged } from '@firebase/auth';
import SignIn from './Components/Functionality/signIn/SignIn';
import Register from './Components/Functionality/signIn/Register';
import GA4React from 'ga-4-react';
import { ForgotPassword } from './Components/Functionality/signIn/Forgotpassword';

export const LoginContext = createContext();
export const UserEmail = createContext();

const ga4react = new GA4React("G-9CC5N8N5QZ", {debug_mode: true});
function ScrollToTop({ children }) {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}

function App() {
	const [isUserSignedIn, setIsUserSignedIn] = useState(true);
	const [email, setEmail] = useState("");
	useEffect(() => {
		ga4react.initialize().then(
		  (ga4) => {
			ga4.pageview("path");
			ga4.gtag("pageview", "path"); // or your custom gtag event
		  },
		  (err) => {
		  }
		);
	  });
	
	onAuthStateChanged(userAuth, (user) => {
		if (user) {
		  setEmail(user.email);

		  UserEmail.Provider = user.email;
		  return setIsUserSignedIn(true);      
		}
		setIsUserSignedIn(false);
	  });
	  

	  if (isUserSignedIn === true) {
		  return (
			<HelmetProvider>
			<Router>
				<LoginContext.Provider value={isUserSignedIn}>
				<Header/>
					<Routes>
						<Route path = "/" element={<Homepage/>}/>
						<Route path="/Contactus" element={<Contactus/>}/>
						<Route path="/SpectralLibrary" element={<SpectralLibraryHomePage/>}/>
						<Route path="/Upload" element={<UploaderHomePage/>}/>
						<Route path="/Chemometrics" element={<Chemometrics/>}/>
						<Route path="/Comingsoon" element={<Comingsoon/>}/>
						<Route path="*" element={<Homepage />} />
					</Routes>
				<Footer/>
				</LoginContext.Provider>
			</Router>
			</HelmetProvider>
  );
} else {
	return (
		<HelmetProvider>
		<Router>
		<LoginContext.Provider value={isUserSignedIn}>
		<Header/>
			<Routes>
				<Route path = "/" element={<Homepage/>}/>
				<Route path="/Login" element={<SignIn/>}/>
				<Route path="/Forgotpassword" element={<ForgotPassword />}/>
				<Route path="/Contactus" element={<Contactus/>}/>
				<Route path="/SpectralLibrary" element={<SpectralLibraryHomePage />}/>
				<Route path="/Upload" element={<SignIn/>}/>
				<Route path="/Comingsoon" element={<Comingsoon/>}/>
				<Route path="/Chemometrics" element={<SignIn/>}/>
				<Route path="/Registerhere" element={<Register />}/>
				<Route path="*" element={<Homepage />} />
			</Routes>
		<Footer/>
		</LoginContext.Provider>
	</Router>
	</HelmetProvider>
	)
	}
}

export default App;
