import React, { useState } from 'react';
import './Navigation.css'; // Styles for the navigation pane
import { Link, useLocation } from 'react-router-dom';

//page metadata
import { pageMetaTags } from '../metaTags/MetaData';
import MetaTags from '../metaTags/MetaTags';

const Navigation = () => {
  const location = useLocation();
  const activeLink = location.pathname.split('/')[1]; // Initially set to the Explore page
  //console.log('active link', activeLink)
  const meta = pageMetaTags.spectralLibrary;

  return (
    <>
    <MetaTags
      title={meta.title}
      description={meta.description}
      url={meta.url}
      image={meta.image}
      siteName={meta.siteName}
      twitterCard={meta.twitterCard}
      twitterSite={meta.twitterSite}
      fbAppId={meta.fbAppId}
    />
    <div className="navigation">
      <ul className="nav-list">
        <li className="nav-item">
          <Link
            to="/SpectralLibrary"
            className={activeLink === 'SpectralLibrary' ? 'active' : ''}
          >
            Explore
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/Upload"
            className={activeLink === 'Upload' ? 'active' : ''}
          >
            Upload Spectra
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/Chemometrics"
            className={activeLink === 'Chemometrics' ? 'active' : ''}
          >
            Chemometrics
          </Link>
        </li>
      </ul>
    </div>
    </>
  );
};

export default Navigation;
