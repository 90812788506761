import React, { useState, useEffect } from 'react'; 
import firebase from "firebase/app";
import {getDatabase} from "firebase/database";
import { Line } from "react-chartjs-2";
import '../SpectralLibrary.css'
import PolymerListInput from './components/PolymerListInput/PolymerListInput';
import InstrumentListInput from './components/InstrumentListInput/InstrumentListInput';
import InstrumentVariedInput from './components/InstrumentVariedInput/InstrumentVariedInput';
import SpectralPlotter from './components/SpectralPlotter/SpectralPlotter';
import Navigation from '../../Components/Functionality/navigation/Navigation';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { fbFunctions } from '../../firebase/firebase';
import '../../App.css'

export const SpectralLibraryHomePage = () => {
    const db = getDatabase();
    const [helloWorldMessage, setHelloWorldMessage] = useState('');
    const [instrumentType, setInstrumentType] = useState('');
    const [polymerTypes, setPolymerTypes] = useState([]);
    const [inputs, setInputs] = useState({ input1: "", input2: "", input3: "" });
    const [showRightColumn, setShowRightColumn] = useState(false);
    const [chartData, setChartData] = useState({});
    const [selectedInstrument, setSelectedInstrument] = useState('');
    const [selectedPolymer, setSelectedPolymer] = useState('');
    const [variableInputValue, setVariableInputValue] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  //Self completing input box from the list of polymer 
  //names pulled down from firebase

  const handlePlot = () => {
    if (selectedInstrument && selectedPolymer && variableInputValue) {
        setShowRightColumn(true);
        // Fetch data from Firebase and update chartData state
      } else alert('Please ensure you have filled out all available fields!')
    };

  const handlePolymerNameChange = (value) => {
    setSelectedPolymer(value);
  };

  const handleInstrumentNameChange = (value) => {
    setSelectedInstrument(value);

  };

  const handleInputTwoChange = (value) => {
    setVariableInputValue(value);
  };


    return (
      <>
      <Navigation/>
        <div className='entireView'>
        <div className='columnLeft'>
          <h2 style={{  color: "#fff", marginLeft: '5%'}}>
            Access our plastic spectral data
            </h2>
          <br />
          <div>
            <InstrumentListInput onChange={handleInstrumentNameChange} />
            <p className='spectralOutputs'>Instrument selected: {selectedInstrument}</p>
            <InstrumentVariedInput instrumentType={selectedInstrument} onChange={handleInputTwoChange} />
         </div>
          <div>
            <PolymerListInput onChange={handlePolymerNameChange} />
            <p className='spectralOutputs'>Polymer type: {selectedPolymer}</p>
          </div>
          <br />
          <button
          className='spectralButton'
          onClick={handlePlot}>
            Plot
            </button>
        </div>
        {showRightColumn && (
          <div className='columnRight'>
            <SpectralPlotter 
            instrumentType={selectedInstrument}
            selectedPolymer={selectedPolymer}
            variableInputValue={variableInputValue}
            pageName='Uploader'
            />
          </div>
        )}
      </div>
      </>
  )
}
