// MetaTags.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { defaultMetaTags } from './MetaData';

const MetaTags = ({ title, description, url, image, siteName, twitterCard, twitterSite, fbAppId }) => {
  return (
    <Helmet>
      <title>{title || defaultMetaTags.title}</title>
      <meta property="og:title" content={title || defaultMetaTags.title} />
      <meta property="og:description" content={description || defaultMetaTags.description} />
      <meta property="og:url" content={url || defaultMetaTags.url} />
      <meta property="og:image" content={image || defaultMetaTags.image} />
      <meta property="og:site_name" content={siteName || defaultMetaTags.siteName} />
      <meta name="twitter:card" content={twitterCard || defaultMetaTags.twitterCard} />
      <meta name="twitter:site" content={twitterSite || defaultMetaTags.twitterSite} />
      <meta property="fb:app_id" content={fbAppId || defaultMetaTags.fbAppId} />
    </Helmet>
  );
};

export default MetaTags;
