import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { fbStorage } from '../../../../firebase/firebase';
import PlotData from './PlotData';
import { Link } from 'react-router-dom';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { app } from '../../../../firebase/firebase';

const AverageSpectrumComponent = ({ instrumentType, selectedPolymer, variableInputValue }) => {
  const [averageSpectrumUrl, setAverageSpectrumUrl] = useState(null);
  const [filesToDownload, setFilesToDownload] = useState([]);

  useEffect(() => {
    getDownloadURL(ref(fbStorage, `${instrumentType}/${selectedPolymer}/${variableInputValue}/averageSpectrum.txt`))
      .then((url) => setAverageSpectrumUrl(url))
      .catch((error) => setAverageSpectrumUrl(null));
  }, [instrumentType, variableInputValue, selectedPolymer]);
  //console.log(averageSpectrumUrl)

  return (
    <div>
      {averageSpectrumUrl ? (
        <>
          <PlotData
            url={averageSpectrumUrl}
            polymer={selectedPolymer}
            pageName={'SpectralLibrary'}
          />
        </>
      ) : (
        <p className='plotHeader'>
          We currently do not have any data available in our database for {selectedPolymer}. However, we continuously update our database and encourage you to become a contributor. If you have access to spectra generated using {instrumentType} with {variableInputValue} nm laser, we invite you to upload your collected data today! Your contribution will greatly assist in the growth of our community. To do so navigate to our <Link to='/Upload'>Upload page!</Link>
        </p>
      )}
    </div>
  );
};

export default AverageSpectrumComponent;
