import React, { useContext, useEffect } from "react";
import "./Header.css";
import plasticAnalyticsNavLogo from "../../../img/plasticAnalytics.png";
import plasticAnalyticsLogo from "../../../img/plasticAnalyticsLogo.png";
import SignInBtn from "./SignIn-SignOutButton";

const Header = () => {
    // Sticky Menu Area
    useEffect(() => {
      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };
    });
  
    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
      const header = document.querySelector(".header-section");
      const scrollTop = window.scrollY;
      scrollTop >= 250
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    };
    return (
      <>
      <h1 className = "Header">
        <a href = "/" className="navBar">
          <img src={plasticAnalyticsLogo} className = "logoHeader"></img>
        </a>
      </h1>
        <header className="header-section d-none d-xl-block">
        <ul className = "navBar">
            <li className = "navBar"><a href="/Login" className = "navBarLeft"> <SignInBtn /></a></li>
            <li className = "navBar"><a href="/Contactus" className = "navBarLeft">Contact Us</a></li>
            <li className="navBar"><a href="/SpectralLibrary" className="navBarLeft">SpectralLibrary</a></li>
            <li className = "navBarRight">
              <a className="navBar" href="/">
                <img src = {plasticAnalyticsNavLogo} width="60" height="35"/>
              </a>
            </li>
        </ul>
        </header>
      </>
    );
  };
  
  export default Header;
  
