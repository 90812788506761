import React, { useRef, useContext} from "react";
import { getAuth } from "firebase/auth";
import { UserEmail } from "../../../App";
import {
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import './SignIn.css'
import { userAuth } from "../../../firebase/firebase";
import { Link } from "react-router-dom";

// meta tags 
import { pageMetaTags } from "../metaTags/MetaData";
import MetaTags from "../metaTags/MetaTags";
function logout() {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
}

const SignIn = (e) => {
  const LoginContext = useContext(UserEmail);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(
      userAuth,
      emailRef.current.value,
      passwordRef.current.value
    )
      .then((user) => {
        window.location.href= '/SpectralLibrary'
      })
      .catch((err) => {
        const errorCode = err.code;
        console.log(errorCode)
        if (errorCode === 'auth/user-not-found'|| errorCode === 'auth/invalid-login-credentials' || errorCode === 'auth/invalid-email'
        ) {
          // Incorrect password or user not found
          alert('Incorrect username. Please try again.');
        } if (errorCode === 'auth/wrong-password'){
          alert('Incorrect password. Please try again.');
        } else {
          
          // Other errors
          alert('Contact site admin at j.levermore@imperial.ac.uk');
        }
      });
  };
  const meta = pageMetaTags.signIn;

  return (
    <>
    <MetaTags
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div className="outer">
        <div className="login-container" id="login-container">
          <div className="form-container log-in-container">
            <form className="login">
              <h1>Login</h1>
              <div className="social-container">
                <span>Sign in with:</span>
                <br />
              </div>
              <label htmlFor="email">Email</label>
              <input type="email" placeholder="Email..." ref={emailRef} />
              <label htmlFor="password">Password (8 characters minimum):</label>
              <input
                type="password"
                minLength="8"
                maxLength="20"
                required
                placeholder="Password..."
                ref={passwordRef}
              />
              <div className="error_response"></div>

              <button onClick={signIn} className="login-button">Log In</button>
              <br />
              <button className="login-button">
                <Link to="/Registerhere" className="nav_email">
                  Register Here
                </Link>
              </button>
              <br />
              <button className="login-button">
                <Link to="/Forgotpassword" className="nav_email">
                  Forgot password
                </Link>
              </button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-right">
                <h1 className="login">Welcome to Our spectral library!</h1>
                <p className="login">
                  To ensure the data hosted in our collective spectral library remains of the highest quality, we ask that you login via your 'ac.uk' credentials so may verify each user. 
                </p>
              </div>
            </div>
          </div>
          <div id="logged-in">
            <h3>You are logged in</h3>
            <button onClick={logout}></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
