import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { userAuth } from "../../../firebase/firebase";
import './SignIn.css';
import { pageMetaTags } from "../metaTags/MetaData";
import MetaTags from "../metaTags/MetaTags";
export const ForgotPassword = () => {

  const [registerEmail, setRegisterEmail] = useState("");

  const forgotPassword = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(userAuth, registerEmail);
      alert('Please check your emails to change your password');
    } catch (error) {
      console.error("Error sending password reset email: ", error);
      alert('Failed to send password reset email');
    }
  };

  const meta = pageMetaTags.forgotPassword;

  return (
    <>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
    <div className="outer">
      <div className="login-container" id="login-container">
        <div className="form-container log-in-container">
          <form onSubmit={forgotPassword} className="login">
            <div className="social-container"></div>
            <span>Email:</span>
            <input
              placeholder="Email..."
              onChange={(event) => {
                setRegisterEmail(event.target.value);
              }}
            />
            <button
              type="submit"
              className="login-button"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-right">
              <h1 className="login">Forgot your password?</h1>
              <p className="login">Reset your password by typing your email here!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ForgotPassword;
