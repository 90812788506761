import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAuth } from "@firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAahOlYKX1JNKMEA8aj5l_u4uLdpdNRe4g",
  authDomain: "plasticanalytics-ada31.firebaseapp.com",
  databaseURL: "https://plasticanalytics-ada31-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "plasticanalytics-ada31",
  storageBucket: "plasticanalytics-ada31.appspot.com",
  messagingSenderId: "917872845779",
  appId: "1:917872845779:web:923c9455e50ec381edc526",
  measurementId: "G-9CC5N8N5QZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };
export const db = getDatabase(app); 
export const fbStorage = getStorage();
export const fbFunctions = getFunctions();
export const userAuth = getAuth();