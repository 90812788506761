// Page headers for the site - stored in a scalable fashion
export const defaultMetaTags = {
    title: 'Plastic Analytics',
    description: 'At PlasticAnalytics, our mission is to conduct research in polymer chemistry focusing on the environmental presence of micro- and nano- plastics. ',
    url: 'https://plasticanalytics.co.uk',
    image: 'https://plasticanalytics.co.uk/static/media/plasticAnalyticsLogo.016046c728d51056d299.png',
    siteName: 'Plastic Analytics',
    twitterCard: 'Plastic Analytics | Homepage',
    twitterSite: '@plasticanalytic',
    fbAppId: 'default_fb_app_id',
  };
  
  export const pageMetaTags = {
    home: {
        title: 'Plastic Analytics',
        description: 'At PlasticAnalytics, our mission is to conduct research in polymer chemistry focusing on the environmental presence of micro- and nano- plastics.',
        url: 'https://plasticanalytics.co.uk',
        image: 'https://plasticanalytics.co.uk/static/media/plasticAnalyticsLogo.016046c728d51056d299.png',
        siteName: 'Plastic Analytics',
        twitterCard: 'Plastic Analytics | Homepage',
        twitterSite: '@plasticanalytic',
        fbAppId: 'default_fb_app_id',
    },
    contactUs: {
        title: 'Contact us | Plastic Analytics',
        description: 'Contact team members at Plastic Anlytics. Lead Lead Web Developer: Dr. Joseph Levermore; Microplastic Team Lead, ICL: Dr. Stephanie Wright',
        url: 'https://plasticanalytics.co.uk/Contactus',
        image: 'https://plasticanalytics.co.uk/static/media/plasticAnalyticsLogo.016046c728d51056d299.png',
        siteName: 'Contact us | Plastic Analytics',
        twitterCard: 'Plastic Analytics | Contact us',
        twitterSite: '@plasticanalytic',
        fbAppId: 'default_fb_app_id',
    },
    signIn: {
        title: 'Sign in | Plastic Analytics',
        description: 'Sign into our plastic spectral chemometric suite',
        url: 'https://plasticanalytics.co.uk/Login',
        image: 'https://plasticanalytics.co.uk/static/media/plasticAnalyticsLogo.016046c728d51056d299.png',
        siteName: 'Sign in | Plastic Analytics',
        twitterCard: 'Plastic Analytics | Sign in',
        twitterSite: '@plasticanalytic',
        fbAppId: 'default_fb_app_id',
      },
    registerToday: {
        title: 'Register today | Plastic Analytics',
        description: 'Create a user profile here.',
        url: 'https://plasticanalytics.co.uk/Registerhere',
        image: 'https://plasticanalytics.co.uk/static/media/plasticAnalyticsLogo.016046c728d51056d299.png',
        siteName: 'Register today | Plastic Analytics',
        twitterCard: 'Plastic Analytics | Register Today',
        twitterSite: '@plasticanalytic',
        fbAppId: 'default_fb_app_id',
    }, 
    forgotPassword: {
        title: 'Forgot Password | Plastic Analytics',
        description: 'Create a user profile here.',
        url: 'https://plasticanalytics.co.uk/Registerhere',
        image: 'https://plasticanalytics.co.uk/static/media/plasticAnalyticsLogo.016046c728d51056d299.png',
        siteName: 'Forgot Password | Plastic Analytics',
        twitterCard: 'Plastic Analytics | Forgot Password',
        twitterSite: '@plasticanalytic',
        fbAppId: 'default_fb_app_id',
    }, 
    spectralLibrary: {
        title: 'Spectral Library | Plastic Analytics',
        description: 'Our flagship chemometric plastic spectral library - categorise your FTIR or Raman spectra here!',
        url: 'https://plasticanalytics.co.uk/Registerhere',
        image: 'https://plasticanalytics.co.uk/static/media/plasticAnalyticsLogo.016046c728d51056d299.png',
        siteName: 'Spectral Library | Plastic Analytics',
        twitterCard: 'Plastic Analytics | Spectral Library',
        fbAppId: 'default_fb_app_id',
    },
  };
  