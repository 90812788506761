import React, { useState, useEffect } from 'react'; 
import { getFunctions, httpsCallable } from "firebase/functions";
import '../SpectralLibrary.css'
import { app, fbStorage } from '../../firebase/firebase';
import { uploadBytes, ref, deleteObject, getMetadata, updateMetadata } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PolymerListInput from '../spectralLibraryHomePage/components/PolymerListInput/PolymerListInput';
import InstrumentListInput from '../spectralLibraryHomePage/components/InstrumentListInput/InstrumentListInput';
import InstrumentVariedInput from '../spectralLibraryHomePage/components/InstrumentVariedInput/InstrumentVariedInput';
import Navigation from '../../Components/Functionality/navigation/Navigation';
import PlotData from '../spectralLibraryHomePage/components/SpectralPlotter/PlotData';
import MetaData from '../spectralLibraryHomePage/components/MetaData/MetaData';
import { userAuth } from '../../firebase/firebase';
import '../../App.css'

export const UploaderHomePage = () => {
    const [inputs, setInputs] = useState({ input1: "", input2: "", input3: "" });
    const [showRightColumn, setShowRightColumn] = useState(false);
    const [selectedInstrument, setSelectedInstrument] = useState('');
    const [selectedMetaDropName, setSelectedMetaDropName] = useState('');
    const [metadataFields, setMetadataFields] = useState({
      inputOneValue: '',
      inputTwoValue: '',
      inputThreeValue: '',
      inputFourValue: '',
    });
    const [startPoint, setStartPoint] = useState('');
    const [endPoint, setEndPoint] = useState('');
    const [fileCode, setFileCode] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [selectedPolymer, setSelectedPolymer] = useState('');
    const [variableInputValue, setVariableInputValue] = useState('');
    const [variableTwoInputValue, setVariableTwoInputValue] = useState('');
    const [userInfo, setUserInfo] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const functions = getFunctions(app);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((prevState) => ({ ...prevState, [name]: value }));
    };
    
  const handlePolymerNameChange = (value) => {
    setSelectedPolymer(value);
  };

  const handleInstrumentNameChange = (value) => {
    setSelectedInstrument(value);
  };
  const handleMetaDropNameChange = (value) => {
    setSelectedMetaDropName(value);
    //console.log('meta data,', value);
  };

  function generateRandomFileCode(length) {
    let charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';
  
    for (let i = 0; i < length; i++) {
      let randomIndex = Math.floor(Math.random() * charset.length);
      code += charset.charAt(randomIndex);
    }
    return code;
  }

  const handleInputTwoChange = (value) => {
    setVariableInputValue(value);
  };
  
    const handleFile = (e) => {
      if (e.target.files[0]) {
        setSelectedFile(e.target.files[0]);
      }
    };
    const handleMetadataInputChange = (fieldName, value) => {
      setMetadataFields({
        inputOneValue: fieldName.inputOneValue,
        inputTwoValue: fieldName.inputTwoValue,
        inputThreeValue: fieldName.inputThreeValue,
        inputFourValue: fieldName.inputFourValue,
      });
    };
    
    const handleUpload = async () => {
      
        if (
          selectedFile && selectedInstrument && selectedPolymer && 
          variableInputValue &&
          selectedMetaDropName          
          ) {
            try {    
                let fileCode = generateRandomFileCode(8);
                setFileCode(fileCode); 
                const storageRef = ref(fbStorage, `${selectedInstrument}/tempFolder/${fileCode}`);
                await uploadBytes(storageRef, selectedFile);
                setShowRightColumn(true); 
                //console.log('Download URL:', downloadURL);
                //console.log('file name', fileCode);               
  
                // Once you have the download URL, proceed with further operations
                const instance = httpsCallable(functions, 'data_upload_and_storage');
                //console.log(selectedInstrument)
                const result = await instance({ 
                    fileName: fileCode,
                    instrument: selectedInstrument,
                    variable: variableInputValue,
                    polymer: selectedPolymer,
                });
                //console.log('result',result)
                setFileUrl(result.data.save_path);
                await uploadBytes(storageRef, selectedFile);
                const deleteRef = ref(fbStorage,`${selectedInstrument}/tempFolder/${fileCode}`);
                //console.log(`${selectedInstrument}/tempFolder/${fileCode}`)
                deleteObject(deleteRef).then(() => {
                  // File deleted successfully
                }).catch((error) => {
                  // error occurred!
                });
                const fileRef = ref(fbStorage, `${result.data.save_path}`);
                setStartPoint(result.data.start_point)
                setEndPoint(result.data.end_point)
                setUserInfo(userAuth.currentUser.email)
                const newMetadata = {
                  customMetadata: {
                    'start_point':`${startPoint}`,
                    'end_point':`${endPoint}`,
                    'type':`${selectedMetaDropName}`,
                    'source': `${metadataFields.inputOneValue}`, 
                    'colour': `${metadataFields.inputTwoValue}`, 
                    'associatedPublication': `${metadataFields.inputThreeValue}`, 
                    'description': `${metadataFields.inputFourValue}`, 
                    'uploadedBy': `${userAuth.currentUser.email}`
                  }
                };
                updateMetadata(fileRef, newMetadata)
                .then(() => {
                  //console.log('Metadata updated successfully'); 
                  return getMetadata(fileRef);
                })
                .then((fetchedMetadata) => {
                  //console.log(fetchedMetadata); 
                })
                .catch((error) => {
                   //console.log(error);
                });
                
                toast.success('Spectra uploaded successfully!', {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 150, // milliseconds
                  style: {
                    color: '#282c34', // Blue text color
                  },
                  progressStyle: {
                    background: '#282c34', // Orange progress bar color
                  },
                });

            } catch (error) {
                //console.error('Error sending file for preprocessing:', error);
            }
            //console.log(fileUrl)

        } else {
            //alert('Please ensure you have filled out all available fields!');
        }
    };
    

    return (
      <>
      <Navigation/>
        <div className='entireView'>
        <div className='columnLeft'>
          <h2 style={{  color: "#fff", marginLeft: '5%'}}>
            Upload your plastic spectra
            </h2>
          <br />
          <div>
            <InstrumentListInput onChange={handleInstrumentNameChange} />
            <p className='spectralOutputs'>Instrument selected: {selectedInstrument}</p>
            <InstrumentVariedInput instrumentType={selectedInstrument} onChange={handleInputTwoChange} />
         </div>
          <div>
            <PolymerListInput onChange={handlePolymerNameChange} />
            <p className='spectralOutputs'>Polymer type: {selectedPolymer}</p>
          </div>
          <div>
            <h3 className='spectralPlotter'>
              Sample metadata:
            </h3>
            <MetaData onChange={handleMetaDropNameChange} onInputChange={handleMetadataInputChange} />
         </div>
          <br />
          <input className='uploadInput' type="file" onChange={handleFile}/>
          <button
          className='spectralButton'
          onClick={handleUpload}>
            Upload
            </button>
        </div>
        {showRightColumn && (
          <div className='columnRight'>
            <PlotData  
            url={fileUrl}
            pageName={'Uploader'}
            polymer={selectedPolymer}
            />
          </div>
        )}
              <ToastContainer />
      </div>
      </>
  )
}
