import React from 'react';
import { kings, imperial, designModo, mrcLogo, linkedIn, twitter, message, instagram, reseachGate, linkedInOrange } from "../../../img/logo/index.js";
import {josephLevermore, stephanieWright, frankKelly} from "../../../img/team/index.js";
import {labImage, wasteImage} from "../../../img/environmentalImages/index.js";
import "./Homepage.css";

// meta data
import MetaTags from '../metaTags/MetaTags.js';
import { pageMetaTags } from '../metaTags/MetaData.js';
function Homepage () {
  const meta = pageMetaTags.home;
  console.log(meta)
	return(
		<React.Fragment>
			<MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
			/>
      <div className="outer">
        <div className="home-top-video">
          <video autoPlay muted loop width="100%">
                <source src="https://firebasestorage.googleapis.com/v0/b/plasticanalytics-ada31.appspot.com/o/landingPage%2FpromoVideo.mp4?alt=media&token=19e8461c-e9ef-4b0a-aabc-e2e47d7ff794" type="video/mp4"/>
                <source src="https://firebasestorage.googleapis.com/v0/b/plasticanalytics-ada31.appspot.com/o/landingPage%2FpromoVideo.mp4?alt=media&token=19e8461c-e9ef-4b0a-aabc-e2e47d7ff794" type="video/ogg"/>
                <source src="https://firebasestorage.googleapis.com/v0/b/plasticanalytics-ada31.appspot.com/o/landingPage%2FpromoVideo.mp4?alt=media&token=19e8461c-e9ef-4b0a-aabc-e2e47d7ff794" type="video/webm"/>
          Your browser does not support the video tag. We suggest you upgrade your browser.
          </video>
          <div className="home-text">
            <h1 className='home-text'>
              PLASTIC ANALYTICS
            </h1>
            <h3 className='home-text'>
              Research. Discover. Collaborate.
            </h3>
          </div>
        </div>
          <div className="headerText">
            <h1 className='Header'>
              PLASTIC ANALYTICS
            </h1>
            <br/>
            <h3>
              Research. Discover. Collaborate.
            </h3>
          </div>
		    </div>

			<div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="header" >
              <h1 className="about">
                ABOUT PLASTIC ANALYTICS
              </h1>
            </div>
            <p className= "subheading" >
              Research. Discover. Collaborate.
            </p>
            <p className="three">
              At PlasticAnalytics, our mission is to conduct research in polymer chemistry focusing on the environmental presence of micro- and nano- plastics. To do so, PlasticAnalytics have created an open-access polymer spectral database, populated with Pyrolysis Gas Chromatography Mass Spectrometry, Raman and Fourier Transform Infrared Spectroscopy information relating to the identification of polymers. 
              This open-access database also enables users to conduct spectral pre-processing and chemometric classification of broad and image spectral datasets via our <i>‘Polypython’</i> SDK. <i>‘Polypython’</i> is not only available in this SDK form, but is also downloadable as a cross-platform desktop application <a className="aboutpage" href="https://pypi.org/project/polypython/">here</a>.
              <br/>
              <br/>
              In addition to the chemometric features provided by PlasticAnalytics, we have also created an online environment aimed at facilitating open communication and dialogue between polymer researchers via our forums, and monthly plastic and microplastics newsletter.
            </p>
              &nbsp;
            <p className="three">
              <a href="https://environment-health.ac.uk/" className="affiliatedInstituion">
               PlasticAnalytics is based in the Medical Research Council's Centre for Environment and Health at Imperial College London, United Kingdom.
              </a>
            </p> 
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columnLogo">
          <a href="https://www.imperial.ac.uk/">
            <img src={imperial} className="logo"/>
          </a>
        </div>                  
        <div className="columnLogo">
          <a href="http://www.environment-health.ac.uk/">
            <img src={mrcLogo} className="logo"/>
          </a>
        </div>
      </div>
		  <main className = "background-grey">
			  <section className="background-grey">
          <div className="card-about">
            <div className="card-horizontal">
              <div className="img-square-wrapper">
                <img src={labImage} alt="Laboratory Image" className="image_hyperlinks"/>
              </div>
              <div className="card-body">
                <ul className="no-bullets">
                  <li>
                    <a href="analytical_chemistry.html" className="aboutpage2">
                      <h1 className="card-title">
                        PLASTIC SPECTRAL LIBRARY
                      </h1>
                    </a>
                  </li>
                </ul>
                  <hr className="aboutpage"/>
                  <i>
                    <p className="about-Plasticanalytics">
                      Analytical research is paramount for the compositional categorisation of plastics and microplastics in environmental samples
                    </p>
                  </i>
                  <p className = "about-PlasticanalyticsThree">
                    To determine the environmental prevalence of plastics and understand what that means for human health, compositional analysis of said entities is necessary. Spectral analysis of identified particulates typically involves the usage of Spectroscopy (Fourier Transform Infrared and Raman) and Spectrometry methods (Pyrolysis-gas chromatography-Mass spectroscopy). Why not login and use our open-source online spectral library <a className="aboutpage" href="/SpectralLibrary">here</a>. 
                  </p>
              </div>
            </div>
          </div>
           	<br/>
           	<div className="card-about">
            	<div className="card-horizontal">
                  <div className="img-square-wrapper">
                  <img src={wasteImage} alt="Plastic Waste" className="image_hyperlinks"/>
            	</div>
                  	<div className="card-body">
						<ul className="no-bullets">
						<li>
							<a href="analytical_chemistry.html" className="aboutpage2">
									<h1 className="card-title">
										ENVIRONMENTAL PREVALENCE OF AIRBORNE MICROPLASTICS
									</h1>
								</a>
							</li>
						</ul>
                  	<hr className="aboutpage"/>
                  	<i>
					  	<p className="about-PlasticanalyticsTwo">
						  Airborne micro- and nano- plastic pollution is a global phenomena, requiring global perspectives.
						</p>
					</i>
                  		<p className="about-PlasticanalyticsThree">
						  With seminal publications being released in 2015, airborne microplastics are an emerging airborne particulate being found at varying concentrations globally. To assist researchers in their understanding of this emerging pollutant’s global distribution, PlasticAnalytics have created an interactive map for you to explore the field's reported findings. <a className="aboutpage" href="/Comingsoon">Click here to explore.</a> 
						</p>
                  </div>
            	</div>
     		</div>
			<div className="container">
      			<div className="row justify-content-center">
            		<div className="col-md-8">
                  		<h1 className="our_team">OUR TEAM</h1>
                        	<div className="row_our_team">
                              <div className="column_our_team">
                              <img src={frankKelly} alt="" className="our_team_responsive"/>
                                    <h2 className="our_team">
										Professor Frank J Kelly
									</h2>
									<div className = "centreIccon">
										<a className = "our_team" href="https://twitter.com/centreenvhealth/">
											<img src={linkedInOrange} className="logoIcon"></img>
										</a>&nbsp;
										<a className = "our_team" href="mailto:fxxraxxnk.kexxxlly@imxxperixxal.acxx.ukxx" onmouseover="this.href=this.href.replace(/x/g,'');">
											<img src={message} className="logoIcon"></img>
										</a>&nbsp;
										<a className = "our_team" href="https://www.researchgate.net/profile/Frank-Kelly-5/">
											<img src={reseachGate} className="logoIcon"></img>
										</a> &nbsp;
									</div>
                                    <h3 className="our_team">
										Program Lead
									</h3>
                                    <h3 className="our_team_sub">
										School of Public Health
									</h3>
                                    <h3 className="our_team">
										Imperial College London
									</h3>
                                <p className="our_team">
									Professor Frank Kelly, PhD, FKC, FRSB, FRSC, FMedSci, holds the inaugural Humphrey Battcock Chair in Community Health and Policy within the School of Public Health, in a new global centre of air pollution rese arch. He previously served as Chair of Environmental Health at King's College London, where he was Director of the Environmental Research Group, Director of the NIHR Health Protection Research Unit in Health Impact of Environmental Hazards and Deputy Director of the MRC Centre for Environment & Health. 
								</p>
                              	</div>
                              	<div className="column_our_team">
                                    <img src={stephanieWright} alt="" className="our_team_responsive"/>
                                          	<h2 className="our_team">
											  Dr. Stephanie L Wright
											</h2>
											<div className = "centreIccon">
												<a className = "our_team" href="https://twitter.com/MarineSteph">
													<img src={linkedInOrange} className="logoIcon"></img>
												</a>&nbsp;
												<a className = "our_team" href="mailto:sxx.wxxxrighxxxt19@xximpexxrixxal.xxxac.uxxk" onmouseover="this.href=this.href.replace(/x/g,'');">
													<img src={message} className="logoIcon"></img>
												</a>&nbsp;
												<a className = "our_team" href="https://www.researchgate.net/profile/Stephanie-Wright-15/">
													<img src={reseachGate} className="logoIcon"></img>
												</a> &nbsp;
											</div>
                                          	<h3 className="our_team">
												Founder
											</h3>
                                          	<h3 className="our_team_sub">
												School of Public Health
											</h3>
                                          	<h3 className="our_team">
												Imperial College London
											</h3>
                                                <p className="our_team">
													Dr. Stephanie Wright is a Lecturer at the Medical Research Council (MRC) Centre for Environment and Health, School of Public Health. She now holds a UKRI Rutherford Fellowship and leads the Microplastics team, whose interdisciplinary research addresses microplastic detection, characterisation and quantification in the atmospheric environment; optimisation of techniques for the detection of microplastics in biological matrices; and toxicological assessment of microplastics using in vitro models of the human airway.
												</p>
                              			</div>
                              		<div className="column_our_team">
                                    	<img src={josephLevermore} alt="" className="our_team_responsive"/>
                                    	<h2 className="our_team">
											Dr. Joseph M Levermore
										</h2>
										<div className = "centreIccon">
											<a className = "our_team" href="https://twitter.com/josephlevermore">
												<img src={linkedInOrange} className="logoIcon"></img>
											</a>&nbsp;
											<a className = "our_team" href="mailto:j.lexxvexxrmore@ixxmperixxal.axxc.xxuk" onmouseover="this.href=this.href.replace(/x/g,'');">
												<img src={message} className="logoIcon"></img>
											</a>&nbsp;
											<a className = "our_team" href="https://www.researchgate.net/profile/Joseph-Levermore/">
												<img src={reseachGate} className="logoIcon"></img>
											</a> &nbsp;
										</div>
												<h3 className="our_team">Founder / CEO</h3>
												<h3 className="our_team_sub">School of Public Health</h3>
												<h3 className="our_team">Imperial College London</h3>
                                		<p className="our_team">
											Dr. Joseph Levermore is a Research Associate at the Medical Research Council (MRC) Centre for Environment and Health, School of Public Health. He developed a deep interest and expertise in Toxicology and its applications to human health, inspiring him to research the toxicological implications of an emerging airborne particulate, microplastics. He now holds an AXA Future Leaders Fellowship exploring the respiratory health of manual sorters in plastic recycling facilities. 
										</p>
                              </div>
                            </div>    
						</div>
					</div>
				</div>
			</section>
		</main>
		</React.Fragment>
	);
}

export default Homepage;


