import { useContext } from "react";
import { LoginContext } from "../../../App";
import { signOut, getAuth } from "firebase/auth";
import { userAuth } from "../../../firebase/firebase";
import './Header.css'
const SignInBtn = () => {
  
  const isUserSignedIn = useContext(LoginContext);
  const handleLogin = () => {
    if (!isUserSignedIn) {
      ///This opens the Educo page instead of the google authenticator 
      window.location.href = "/SignIn";
      // Call email at signIn
    } else {
      signOut(userAuth).then(() => {

      })
    }
  };
  //console.log('user signed', isUserSignedIn)

  return (
    <button className="login-button" id="signedIn" onClick={handleLogin}>
      {!isUserSignedIn ? "Log In" : "Log Out"}
    </button>
  );
};

export default SignInBtn;