import { fbStorage } from '../../../../firebase/firebase';
import { db } from '../../../../firebase/firebase';
import React, { useEffect, useState } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import './PlotData.css'; 
Chart.register(...registerables);

const PlotData = ({ url, pageName, match, instrument, variable, polymer }) => {
  const [data, setData] = useState(null);
  const [xdata, setXData] = useState(null);
  const [fileUrl, setFileUrl] = useState(url);  
  const [additionalFileUrl, setAdditionalFileUrl] = useState(url);  
  const [selectedPolymer, setSelectedPolymer] = useState('');

  const [selectedHeader, setSelectedHeader] = useState('');
  const [additionalData, setAdditionalData] = useState(null);
  const [additionalXData, setAdditionalXData] = useState(null);
  const [chartImage, setChartImage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (fileUrl !== null) {
        if (url && url.data && url.data.result !== undefined) {
          setFileUrl(url.data.save_path);
        } else {
          setFileUrl(url);
        }
        const fileRef = ref(fbStorage, fileUrl);
        const downloadURL = await getDownloadURL(fileRef);
        //console.log('download',downloadURL);
        const response = await fetch(downloadURL);
        //console.log('response', response)
        const textData = await response.text();
        //console.log(textData)
        const rows = textData.split('\n');
        //console.log('here', polymer)
        setSelectedPolymer(polymer)

        let xData = [];
        let yData = [];

        rows.forEach((row, index) => {
          const [x, y] = row.split('\t');
          xData.push(parseFloat(x));
          yData.push(parseFloat(y));
        });

        setData(yData);
        setXData(xData);
      } else {
        //console.log('Error fetching data:');
      }
    };
    if (fileUrl !== null) {
      if (pageName === 'Uploader') {
        setSelectedHeader(`Your uploaded ${polymer} spectrum!`);
      } else if (pageName === 'SpectralLibrary') {
        setSelectedHeader(`Our Average Spectrum for ${polymer}`);
      } else if (pageName === 'Chemometrics'){
        setSelectedHeader('Our spectral composition prediction');
      }
      fetchData();
    } else {
      //console.log('Error fetching data: fileUrl is null');
    }
  }, [fileUrl, url]); 

  useEffect(() => {
    const fetchAdditionalData = async () => {
      //console.log(pageName)
      // Adjust this part based on how you fetch the additional data
      if (additionalFileUrl !== null) {
        if (pageName === 'Chemometrics') {
          //console.log('hello', instrument, variable, match)
          setAdditionalFileUrl(`${instrument}/${match}/${variable}/averageSpectrum.txt`);
        } else {
          
          setAdditionalFileUrl(null)
        }
        //pulling down the user's spectra
        const fileRef = ref(fbStorage, fileUrl);
        const downloadURL = await getDownloadURL(fileRef);
        const response = await fetch(downloadURL);
        const textData = await response.text();
        const rows = textData.split('\n');
        let xData = [];
        let yData = [];

        rows.forEach((row, index) => {
          const [x, y] = row.split('\t');
          xData.push(parseFloat(x));
          yData.push(parseFloat(y));
        });

        setData(yData);
        setXData(xData);
        //pulling down match spectra
        //console.log(additionalFileUrl)
        const matchRef = ref(fbStorage, additionalFileUrl);
        //console.log('match ref', matchRef)
        const matchDownloadURL = await getDownloadURL(matchRef);
        //console.log('match url', matchDownloadURL)
        const matchResponse = await fetch(matchDownloadURL);
        const matchTextData = await matchResponse.text();
        //console.log('match text', matchTextData)

        const matchRows = matchTextData.split('\n');

        let additionalXData = [];
        let additionalYData = [];

        matchRows.forEach((row, index) => {
          const [x, y] = row.split('\t');
          additionalXData.push(parseFloat(x));
          additionalYData.push(parseFloat(y));
        });
        setAdditionalData(additionalYData);
        setAdditionalXData(additionalXData);

    };
  }

    if (pageName === 'Chemometrics') {
      fetchAdditionalData();
    }
  }, [fileUrl, url, pageName, additionalFileUrl]);

  const LineChart = ({ data }) => {
    return (
      <div>
        <Line
          data={{
            labels: data.xdata,
            datasets: [
              {
                label: 'Spectra',
                data: data.data,
                backgroundColor: 'rgb(255, 87, 51)',
                borderColor: 'rgba(39, 55, 70, 1)',
                borderWidth: 1,
              },
            ],
          }}
        />
      </div>
    );
  };
  const LineChartChemo = ({ data }) => {
    return (
      <div>
        <Line
          data={{
            labels: data.xdata,
            datasets: [
              {
                label: 'Spectral match',
                data: data.data,
                backgroundColor: 'rgb(39, 55, 70, 1)',
                borderColor: 'rgba(255, 95, 51)',
                borderWidth: 1,
              },
            ],
          }}
        />
      </div>
    );
  };


  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
          }
        }
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
          }
        }
      ]
    }
  };

  let chartComponent;
  if (data && data.length > 0) {
    if (pageName === 'Uploader'){
      chartComponent = <LineChart data={{ xdata, data }} polymer={selectedPolymer} options={options} />;
    } else if (pageName === 'Chemometrics') {
        // Fetch additional data (e.g., plastic.data)
        // Update the chartComponent with both spectra
        chartComponent = (
          <div>
            <LineChart data={{ xdata, data }} />
            <LineChartChemo data={{ xdata, data: additionalData }} />
          </div>
        );
    } else if (pageName === 'SpectralLibrary'){
      chartComponent = <LineChart data={{ xdata, data }} polymer={selectedPolymer} options={options} />;
  } else {
    chartComponent = <div>Loading chart...</div>;
  }
}

  return (
    <div>
      <center>
      <h2 className='plotHeader'>
        {selectedHeader ? `${selectedHeader}` : ""}
      </h2>
      </center>
      {chartComponent}
    </div>
  );
};

export default PlotData;
