import React from 'react';
import "./Comingsoon.css";

function Comingsoon(){
	// Set the date we're counting down to
	var countDownDate = new Date("March 5, 2024 12:00:00").getTime();

	// Update the count down every 1 second
	var x = setInterval(function() {

	// Get today's date and time
	var now = new Date().getTime();
		
	// Find the distance between now and the count down date
	var distance = countDownDate - now;
		
	// Time calculations for days, hours, minutes and seconds
	var days = Math.floor(distance / (1000 * 60 * 60 * 24));
	var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	var seconds = Math.floor((distance % (1000 * 60)) / 1000);
		
	// Output the result in an element with id="demo"
	document.getElementById("days").innerHTML = days + "d ";
	document.getElementById("hours").innerHTML = hours + "h ";
	document.getElementById("minutes").innerHTML = minutes + "m ";
	document.getElementById("seconds").innerHTML = seconds + "s ";
		
	// If the count down is over, write some text 
	if (distance < 0) {
		clearInterval(x);
		document.getElementById("demo").innerHTML = "Coming anytime soon";
	}
	}, 1000);
	return(
		<React.Fragment>
			<div class="countDownouter">
				<div class="countDownmiddle">
					<div class="countDowninner">
						<div class="countDowncontainer">
							<h1 id="countDown">The countdown to the launch of our Spectral Library and Interactive map begins!</h1>
							<div id="countdown" className='Centre'>
								<ul>
								<li className='countDown'><span id="days" className='countDown'></span>days</li>
								<li className='countDown'><span id="hours" className='countDown'></span>Hours</li>
								<li className='countDown'><span id="minutes" className='countDown'></span>Minutes</li>
								<li className='countDown'><span id="seconds" className='countDown'></span>Seconds</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Comingsoon;

